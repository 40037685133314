@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input:focus {
  outline: none;
}
.custom-checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
}
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 1px solid #D0D5DD;
  border-radius: 25%;
  padding: 8px;
}
.custom-checkbox input:checked ~ .checkmark {
  background-color: #EBFFF9;
  border: 1px solid green;
}
#subscription input:checked ~ .checkmark {
  background-color: #D6FFF2;
  border: 1px solid #B8EDD7;
}
.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 16px;
  width: 16px;
  background-image: url("assets/check.svg");
  background-size: cover;
}
#subscription .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 16px;
  width: 16px;
  background-image: url("assets/subscription-check.svg");
  background-size: cover;
}
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}
#subscription input:checked ~ .checkmark:after {
  display: block;
}
/*Pop Up style*/
.character {
  width: 64px;
  height: 64px;
  color: #D0D5DD;
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
}
.character--selected {
  border: 1px solid #B8EDD7;
  background: #FFF;
  outline: 2px solid #B8EDD7;
}
.character--filled {
  color: #00B27A;
  border: 1px solid #B8EDD7;
  background: #FFF;
}
